import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"

const FormContainer = styled.form.attrs({
  id: "Contact",
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #305244;
  padding: 0 24px 48px;
  h3 {
    color: #fdfdfd;
  }
  div button {
    margin-top: 16px;
  }
  svg {
    display: none;
  }
  @media screen and (min-width: 450px) {
    padding: 0 32px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 0 48px 32px;
  }
  @media screen and (min-width: 650px) {
    padding: 0 64px 48px;
  }
  @media screen and (min-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "header header"
      "form img";
    column-gap: 16px;
    h3 {
      width: 70%;
      grid-area: header;
    }
    div {
      grid-area: form;
    }
    svg {
      display: block;
      width: 100%;
      /* margin-left: 10%; */
      grid-area: img;
      justify-self: end;
      align-self: center;
    }
  }
  @media screen and (min-width: 800px) {
    padding: 0 72px 48px;
    grid-template-columns: 0.8fr 1.2fr;
    h3 {
      width: 65%;
    }
  }
  @media screen and (min-width: 950px) {
    padding: 0 80px 48px;
    svg {
      width: 80%;
    }
  }
  @media screen and (min-width: 1050px) {
    padding: 0 96px 64px;
  }
  @media screen and (min-width: 1230px) {
    grid-template-columns: 0.75fr 1.3fr;
    h3 {
      width: 60%;
    }
    svg {
      width: 70%;
    }
  }
`
const InputContainer = styled.div`
  label {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    input,
    textarea {
      resize: none;
      margin-top: 4px;
      /* width: 100cw; */
    }
    span span {
      color: #d90207;
    }
  }
  @media screen and (min-width: 750px) {
    width: 100%;
  }
`

const SubmitButton = styled.div`
  width: 100%;
  margin-top: 16px;
  input {
    width: 100%;
    padding: 12px 24px;
    background: #7bc8a8;
    border-radius: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #fdfdfd;
    border: none;
    height: auto;
    margin-top: 6px;
  }
`

export default class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    captchaVal: false,
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleCaptchaVerification = event => {
    this.setState({
      captchaVal: true,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    const captchaPassed = this.state.captchaVal

    if (captchaPassed) {
      const url = `https://docs.google.com/forms/d/e/1FAIpQLScJ0RDEmj7bbjtT35J31fXJ70HvQFOm6ZYSdiCjHbf6_60SqA/formResponse?usp=pp_url&entry.1679574114=${this.state.name}&entry.1771109062=${this.state.email}&entry.1075185580=${this.state.message}`
      window.open(url, "_self")
      this.setState({
        name: "",
        email: "",
        message: "",
        captchaVal: false,
      })
      window.open("#", "_blank")
      console.log("verification passed")
    } else {
      alert("Verification failed. Please verify by checking the checkbox")
    }
  }
  render() {
    return (
      <FormContainer onSubmit={this.handleSubmit}>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet>
        <h3>Interested in doing business with us? Leave us a message!</h3>
        <div>
          <InputContainer>
            <label htmlFor="name">
              <span>
                Name <span>*</span>
              </span>
              <input
                id="name"
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <InputContainer>
            <label htmlFor="email">
              <span>
                Email <span>*</span>
              </span>
              <input
                id="email"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <InputContainer>
            <label htmlFor="message">
              <span>
                Message <span>*</span>
              </span>
              <textarea
                id="message"
                name="message"
                rows="8"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <SubmitButton>
            <ReCAPTCHA
              sitekey="6Lf9GBoaAAAAACO3Jyky-zvSutmQej0LQgQQjR30"
              onChange={this.handleCaptchaVerification}
            />
            <input type="submit" value="Submit" />
          </SubmitButton>
        </div>
        <svg
          viewBox="0 0 628 505"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M628 264.014C628 383.642 540.781 482.912 426.467 501.693C413.461 503.825 400.303 504.894 387.124 504.89C355.49 504.896 324.164 498.671 294.937 486.568C265.709 474.465 239.153 456.723 216.784 434.354C194.415 411.985 176.673 385.429 164.57 356.201C152.467 326.974 146.241 295.648 146.248 264.014C146.248 130.985 254.095 23.138 387.124 23.138C437.4 23.061 486.429 38.7902 527.277 68.1015C588.263 111.795 628 183.262 628 264.014Z"
            fill="#7BC8A8"
          />
          <path
            d="M554.327 30.3597L575.247 23.837L577.052 5.41262C577.052 5.41262 526.17 -13.8076 509.903 20.1895C504.159 32.1934 496.921 39.4025 489.958 43.7062C480.368 49.6343 468.548 50.3299 457.957 46.4622C422.694 33.5835 414.389 70.2076 414.389 70.2076C414.389 70.2076 417.438 75.421 439.175 76.7029C449.993 77.3408 458.844 85.4815 465.007 93.4992C478.205 110.668 505.314 105.706 511.97 85.0995C512.078 84.7674 512.183 84.431 512.287 84.0904C522.685 50.0269 570.025 77.1482 570.025 77.1482L573.047 46.2953L554.327 30.3597Z"
            fill="#252525"
          />
          <path
            d="M411.784 113.74C425.934 113.74 437.406 102.269 437.406 88.1177C437.406 73.9668 425.934 62.4952 411.784 62.4952C397.633 62.4952 386.161 73.9668 386.161 88.1177C386.161 102.269 397.633 113.74 411.784 113.74Z"
            fill="#252525"
          />
          <path
            d="M398.973 224.771C436.708 224.771 467.299 194.18 467.299 156.444C467.299 118.709 436.708 88.1177 398.973 88.1177C361.237 88.1177 330.646 118.709 330.646 156.444C330.646 194.18 361.237 224.771 398.973 224.771Z"
            fill="#252525"
          />
          <path
            d="M396.837 228.33C422.781 228.33 443.812 207.299 443.812 181.355C443.812 155.412 422.781 134.381 396.837 134.381C370.894 134.381 349.863 155.412 349.863 181.355C349.863 207.299 370.894 228.33 396.837 228.33Z"
            fill="#CFB7A1"
          />
          <path
            d="M378.332 216.942L386.873 262.493L429.577 256.799C429.577 256.799 422.46 221.212 426.73 211.248L378.332 216.942Z"
            fill="#CFB7A1"
          />
          <path
            d="M167.658 393.452C167.658 393.452 119.26 364.983 114.99 384.912C110.719 404.84 163.388 413.381 163.388 413.381L167.658 393.452Z"
            fill="#CFB7A1"
          />
          <path
            d="M462.317 117.299C462.317 117.299 428.154 91.6764 413.919 101.641C399.684 111.605 452.353 144.345 458.046 141.498C463.74 138.651 462.317 117.299 462.317 117.299Z"
            fill="#CFB7A1"
          />
          <path
            d="M429.577 232.6L378.332 241.141V263.916C378.332 263.916 329.934 279.575 329.934 286.692C329.934 293.809 317.123 320.855 317.123 320.855C317.123 320.855 285.807 343.631 311.429 370.677C311.429 370.677 321.393 386.335 314.276 387.758C307.159 389.182 291.5 393.452 298.618 399.146C305.735 404.84 412.496 443.274 421.036 440.427C429.577 437.58 495.057 336.514 495.057 310.891C495.057 285.269 468.011 248.258 468.011 248.258L436.695 243.988L429.577 232.6Z"
            fill="#E4EFED"
          />
          <path
            d="M334.205 285.269C334.205 285.269 314.276 282.422 307.159 306.621C300.041 330.82 275.842 409.111 275.842 409.111C275.842 409.111 189.011 407.687 166.235 386.335L156.271 419.075C156.271 419.075 287.23 466.05 298.618 457.509C310.006 448.968 359.827 310.891 359.827 310.891L334.205 285.269Z"
            fill="#E4EFED"
          />
          <path
            d="M463.741 252.529L468.011 248.258C468.011 248.258 522.103 189.896 520.679 175.661C520.679 175.661 492.21 140.074 456.623 150.039C456.623 150.039 440.965 111.605 465.164 113.028C489.363 114.452 579.042 108.758 577.618 150.039C576.195 191.319 490.787 318.008 490.787 318.008L463.741 252.529Z"
            fill="#E4EFED"
          />
          <path
            d="M426.467 501.693C413.461 503.825 400.304 504.894 387.124 504.89C345.247 504.955 304.083 494.051 267.73 473.262L276.19 456.131L303.117 401.561L304.314 399.145L323.927 405.058L422.46 434.737L422.73 439.284L426.467 501.693Z"
            fill="#252525"
          />
          <path
            d="M443.443 160.188C448.761 144.559 433.162 125.114 408.602 116.757C384.041 108.4 359.82 114.295 354.502 129.925C349.184 145.554 364.783 164.999 389.343 173.356C413.904 181.713 438.125 175.818 443.443 160.188Z"
            fill="#252525"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M146.528 15.3628L5.77392 15.7047C4.24016 15.7078 2.7704 16.3198 1.68769 17.4061C0.604984 18.4925 -0.00205799 19.9643 5.24225e-06 21.4981L0.641117 280.947C0.643156 281.707 0.794829 282.459 1.08747 283.16C1.3801 283.861 1.80798 284.498 2.34666 285.034C2.88535 285.57 3.52428 285.994 4.227 286.283C4.92972 286.572 5.68246 286.719 6.44223 286.717L147.196 286.375C148.73 286.371 150.199 285.758 151.281 284.671C152.363 283.584 152.969 282.112 152.966 280.578L152.313 21.1289C152.308 19.5979 151.696 18.1313 150.612 17.0505C149.527 15.9697 148.059 15.3628 146.528 15.3628Z"
              fill="url(#paint0_linear)"
            />
            <path
              opacity="0.5"
              d="M144.964 15.1419L7.35431 15.4781C4.42728 15.4853 2.06024 17.8639 2.06739 20.791L2.69517 277.71C2.70232 280.637 5.08095 283.004 8.00799 282.997L145.618 282.661C148.545 282.654 150.912 280.275 150.905 277.348L150.277 20.4288C150.27 17.5018 147.891 15.1347 144.964 15.1419Z"
              fill="white"
            />
            <path
              opacity="0.5"
              d="M146.726 89.2736L146.578 27.4935C146.574 26.001 145.977 24.5712 144.919 23.5183C143.862 22.4655 142.429 21.8758 140.936 21.8789L113.407 21.945C112.986 24.7829 111.563 27.3764 109.396 29.2564C107.229 31.1364 104.46 32.1788 101.591 32.195L50.3993 32.3194C47.5303 32.3173 44.7567 31.2886 42.5802 29.4193C40.4037 27.5499 38.968 24.9634 38.5329 22.1276L11.4157 22.1936C9.92312 22.1978 8.49329 22.7944 7.44045 23.8523C6.38762 24.9102 5.79794 26.3429 5.80104 27.8355L5.95258 90.1362L6.39165 270.682C6.39885 272.174 6.99839 273.603 8.05851 274.654C9.11863 275.704 10.5526 276.291 12.0451 276.285L141.566 275.97C143.054 275.962 144.478 275.365 145.527 274.31C146.576 273.255 147.165 271.828 147.165 270.34L146.726 89.2736Z"
              fill="#BBBBBB"
            />
            <path
              opacity="0.5"
              d="M90.563 24.9985L59.0937 25.4709C58.6645 25.4773 58.3219 25.8304 58.3283 26.2596L58.336 26.7685C58.3424 27.1976 58.6955 27.5403 59.1246 27.5339L90.594 27.0615C91.0231 27.055 91.3658 26.7019 91.3593 26.2728L91.3517 25.7638C91.3453 25.3347 90.9922 24.992 90.563 24.9985Z"
              fill="#DBDBDB"
            />
            <path
              opacity="0.5"
              d="M98.3901 26.9446C99.0746 26.9343 99.6211 26.3711 99.6108 25.6866C99.6006 25.0022 99.0374 24.4556 98.3529 24.4659C97.6684 24.4762 97.1219 25.0394 97.1321 25.7238C97.1424 26.4083 97.7056 26.9549 98.3901 26.9446Z"
              fill="#DBDBDB"
            />
            <path
              opacity="0.5"
              d="M133.542 55.3684H64.5391V60.8703H133.542V55.3684Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 66.3722H64.5391V71.8741H133.542V66.3722Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M30.3422 74.4464C37.5396 74.4464 43.3743 68.5839 43.3743 61.3521C43.3743 54.1203 37.5396 48.2578 30.3422 48.2578C23.1447 48.2578 17.3101 54.1203 17.3101 61.3521C17.3101 68.5839 23.1447 74.4464 30.3422 74.4464Z"
              fill="#F5F5F5"
            />
            <path
              opacity="0.5"
              d="M35.9568 70.5181H24.6538C24.1115 70.5222 23.5783 70.6572 23.0993 70.9115C22.6204 71.1659 22.2099 71.5321 21.9028 71.979C24.4426 73.4361 27.3164 74.2106 30.2445 74.2269C33.1725 74.2433 36.0548 73.501 38.6107 72.0723C38.3237 71.621 37.9332 71.2445 37.4717 70.9742C37.0102 70.704 36.4909 70.5476 35.9568 70.5181Z"
              fill="#7BC8A8"
            />
            <path
              opacity="0.5"
              d="M24.7471 61.6863C24.7471 61.6863 23.0919 66.5044 22.1865 66.0536C22.1865 66.0536 30.9057 73.5838 38.6029 66.0536C37.6766 64.4707 36.5639 63.0044 35.2886 61.6863H24.7471Z"
              fill="#333333"
            />
            <path
              opacity="0.5"
              d="M30.3344 67.7127C33.9116 67.7127 36.8116 64.8128 36.8116 61.2355C36.8116 57.6582 33.9116 54.7583 30.3344 54.7583C26.7571 54.7583 23.8572 57.6582 23.8572 61.2355C23.8572 64.8128 26.7571 67.7127 30.3344 67.7127Z"
              fill="#333333"
            />
            <path
              opacity="0.1"
              d="M28.0575 68.2722H32.5764V70.0285C32.5764 70.5788 32.3578 71.1066 31.9687 71.4957C31.5796 71.8848 31.0518 72.1034 30.5015 72.1034H30.113C29.5627 72.1034 29.0349 71.8848 28.6458 71.4957C28.2567 71.1066 28.0381 70.5788 28.0381 70.0285V68.2722H28.0575Z"
              fill="#252525"
            />
            <path
              opacity="0.5"
              d="M28.5354 68.1635H32.1024C32.2291 68.1635 32.3507 68.2138 32.4403 68.3034C32.5299 68.3931 32.5803 68.5146 32.5803 68.6414V69.9314C32.5803 70.4817 32.3617 71.0094 31.9726 71.3985C31.5835 71.7876 31.0557 72.0062 30.5054 72.0062H30.1169C29.5693 72.0021 29.0455 71.7817 28.6597 71.3931C28.274 71.0044 28.0575 70.479 28.0575 69.9314V68.653C28.056 68.5893 28.0672 68.5259 28.0905 68.4666C28.1138 68.4072 28.1488 68.3531 28.1933 68.3075C28.2378 68.2619 28.2911 68.2257 28.3498 68.2009C28.4086 68.1762 28.4717 68.1634 28.5354 68.1635Z"
              fill="#CFB7A1"
            />
            <path
              opacity="0.1"
              d="M28.0693 68.8162C29.5272 69.3566 31.1304 69.3566 32.5882 68.8162V68.2916H28.0693V68.8162Z"
              fill="#252525"
            />
            <path
              opacity="0.5"
              d="M30.3344 69.1193C33.9116 69.1193 36.8116 66.2194 36.8116 62.6421C36.8116 59.0649 33.9116 56.1649 30.3344 56.1649C26.7571 56.1649 23.8572 59.0649 23.8572 62.6421C23.8572 66.2194 26.7571 69.1193 30.3344 69.1193Z"
              fill="#CFB7A1"
            />
            <path
              opacity="0.5"
              d="M24.1448 60.1825H36.493C36.493 60.1825 35.44 55.1974 30.7696 55.5199C26.0992 55.8424 24.1448 60.1825 24.1448 60.1825Z"
              fill="#333333"
            />
            <path
              opacity="0.5"
              d="M23.9931 63.497C24.3257 63.497 24.5954 62.9908 24.5954 62.3663C24.5954 61.7418 24.3257 61.2356 23.9931 61.2356C23.6605 61.2356 23.3909 61.7418 23.3909 62.3663C23.3909 62.9908 23.6605 63.497 23.9931 63.497Z"
              fill="#CFB7A1"
            />
            <path
              opacity="0.5"
              d="M36.6445 63.497C36.9771 63.497 37.2467 62.9908 37.2467 62.3663C37.2467 61.7418 36.9771 61.2356 36.6445 61.2356C36.3119 61.2356 36.0422 61.7418 36.0422 62.3663C36.0422 62.9908 36.3119 63.497 36.6445 63.497Z"
              fill="#CFB7A1"
            />
            <path
              opacity="0.1"
              d="M24.1448 60.3536H36.493C36.493 60.3536 35.44 55.3684 30.7696 55.6909C26.0992 56.0134 24.1448 60.3536 24.1448 60.3536Z"
              fill="#252525"
            />
            <path
              opacity="0.5"
              d="M133.542 97.2778H64.5391V102.78H133.542V97.2778Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 108.282H64.5391V113.784H133.542V108.282Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M30.3422 116.356C37.5396 116.356 43.3743 110.493 43.3743 103.262C43.3743 96.0297 37.5396 90.1672 30.3422 90.1672C23.1447 90.1672 17.3101 96.0297 17.3101 103.262C17.3101 110.493 23.1447 116.356 30.3422 116.356Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 139.187H64.5391V144.689H133.542V139.187Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 150.191H64.5391V155.693H133.542V150.191Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M30.3422 158.265C37.5396 158.265 43.3743 152.403 43.3743 145.171C43.3743 137.939 37.5396 132.077 30.3422 132.077C23.1447 132.077 17.3101 137.939 17.3101 145.171C17.3101 152.403 23.1447 158.265 30.3422 158.265Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 181.097H64.5391V186.599H133.542V181.097Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 192.101H64.5391V197.603H133.542V192.101Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M30.3422 200.175C37.5396 200.175 43.3743 194.312 43.3743 187.081C43.3743 179.849 37.5396 173.986 30.3422 173.986C23.1447 173.986 17.3101 179.849 17.3101 187.081C17.3101 194.312 23.1447 200.175 30.3422 200.175Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 223.006H64.5391V228.508H133.542V223.006Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M133.542 234.01H64.5391V239.512H133.542V234.01Z"
              fill="#E0E0E0"
            />
            <path
              opacity="0.5"
              d="M30.3422 242.084C37.5396 242.084 43.3743 236.222 43.3743 228.99C43.3743 221.758 37.5396 215.896 30.3422 215.896C23.1447 215.896 17.3101 221.758 17.3101 228.99C17.3101 236.222 23.1447 242.084 30.3422 242.084Z"
              fill="#E0E0E0"
            />
          </g>
          <path
            d="M237.119 47.9586L96.3614 48.3006C94.8276 48.3047 93.3582 48.9176 92.2763 50.0047C91.1943 51.0918 90.5882 52.564 90.5913 54.0978L91.2324 312.276C91.2345 313.036 91.3861 313.788 91.6788 314.489C91.9714 315.191 92.3993 315.827 92.938 316.363C93.4767 316.899 94.1156 317.323 94.8183 317.612C95.521 317.901 96.2738 318.049 97.0335 318.047L237.795 317.705C239.326 317.698 240.792 317.086 241.872 316C242.952 314.915 243.558 313.446 243.557 311.915L242.916 53.7364C242.915 52.9763 242.764 52.2239 242.472 51.5221C242.18 50.8203 241.753 50.1829 241.215 49.6463C240.676 49.1097 240.038 48.6845 239.335 48.3949C238.632 48.1054 237.879 47.9571 237.119 47.9586Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M235.554 46.4672L97.9444 46.8035C95.0174 46.8106 92.6503 49.1892 92.6575 52.1163L93.2853 309.035C93.2924 311.962 95.671 314.329 98.5981 314.322L236.208 313.986C239.135 313.979 241.502 311.6 241.495 308.673L240.867 51.7541C240.86 48.8271 238.481 46.4601 235.554 46.4672Z"
            fill="white"
          />
          <path
            d="M237.317 121.022L237.17 58.8032C237.166 57.3107 236.569 55.8809 235.511 54.828C234.453 53.7752 233.02 53.1855 231.528 53.1886L203.991 53.2547C203.57 56.0926 202.147 58.6861 199.98 60.5661C197.812 62.4461 195.044 63.4885 192.175 63.5047L140.987 63.6291C138.118 63.627 135.344 62.5983 133.168 60.7289C130.991 58.8596 129.556 56.2731 129.12 53.4373L102.003 53.5033C100.511 53.5075 99.0809 54.104 98.0281 55.162C96.9753 56.2199 96.3856 57.6526 96.3887 59.1451L96.5402 121.446"
            fill="#FAFAFA"
          />
          <path
            d="M96.5403 121.45L96.9794 301.995C96.9835 303.488 97.5801 304.918 98.638 305.971C99.6959 307.023 101.129 307.613 102.621 307.61L232.142 307.295C233.634 307.291 235.064 306.695 236.117 305.637C237.17 304.579 237.76 303.146 237.756 301.653L237.313 120.587"
            fill="#FAFAFA"
          />
          <path
            d="M136.507 79.0741H108.99V106.592H136.507V79.0741Z"
            fill="#E0E0E0"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M258 129.45H76.3003V191.258H258V129.45Z"
              fill="url(#paint2_linear)"
            />
          </g>
          <path
            d="M255.84 131.991H78.4646V189.14H255.84V131.991Z"
            fill="white"
          />
          <path
            d="M218.208 153.156H149.205V158.658H218.208V153.156Z"
            fill="#7BC8A8"
          />
          <path
            d="M218.208 164.164H149.205V169.666H218.208V164.164Z"
            fill="#7BC8A8"
          />
          <path
            d="M218.208 214.116H149.205V219.618H218.208V214.116Z"
            fill="#E0E0E0"
          />
          <path
            d="M218.208 225.12H149.205V230.622H218.208V225.12Z"
            fill="#EEEEEE"
          />
          <path
            d="M199.157 101.086H168.256V110.823H199.157V101.086Z"
            fill="#7BC8A8"
          />
          <path
            d="M115.008 172.234C122.206 172.234 128.04 166.371 128.04 159.14C128.04 151.908 122.206 146.045 115.008 146.045C107.811 146.045 101.976 151.908 101.976 159.14C101.976 166.371 107.811 172.234 115.008 172.234Z"
            fill="#F5F5F5"
          />
          <path
            d="M120.623 168.306H109.316C108.774 168.31 108.24 168.445 107.761 168.699C107.282 168.953 106.872 169.32 106.565 169.767C109.105 171.224 111.979 171.998 114.907 172.014C117.835 172.031 120.717 171.288 123.273 169.86C122.986 169.409 122.596 169.033 122.135 168.763C121.675 168.492 121.156 168.336 120.623 168.306Z"
            fill="#7BC8A8"
          />
          <path
            d="M109.413 159.478C109.413 159.478 107.758 164.296 106.852 163.845C106.852 163.845 115.571 171.375 123.269 163.845C122.342 162.262 121.23 160.796 119.954 159.478H109.413Z"
            fill="#333333"
          />
          <path
            d="M115 165.504C118.578 165.504 121.478 162.604 121.478 159.027C121.478 155.45 118.578 152.55 115 152.55C111.423 152.55 108.523 155.45 108.523 159.027C108.523 162.604 111.423 165.504 115 165.504Z"
            fill="#333333"
          />
          <path
            opacity="0.1"
            d="M112.724 166.064H117.242V167.82C117.242 168.37 117.024 168.898 116.635 169.287C116.246 169.676 115.718 169.895 115.168 169.895H114.779C114.229 169.895 113.701 169.676 113.312 169.287C112.923 168.898 112.704 168.37 112.704 167.82V166.064H112.724Z"
            fill="#252525"
          />
          <path
            d="M113.201 165.955H116.768C116.895 165.955 117.017 166.005 117.106 166.095C117.196 166.184 117.246 166.306 117.246 166.433V167.711C117.247 167.984 117.193 168.255 117.089 168.507C116.985 168.76 116.832 168.989 116.639 169.182C116.445 169.375 116.216 169.528 115.964 169.633C115.711 169.737 115.441 169.79 115.168 169.79H114.779C114.229 169.79 113.701 169.571 113.312 169.182C112.923 168.793 112.704 168.265 112.704 167.715V166.437C112.704 166.374 112.716 166.312 112.74 166.254C112.764 166.196 112.8 166.143 112.844 166.099C112.888 166.054 112.941 166.019 112.999 165.995C113.057 165.971 113.119 165.959 113.182 165.959L113.201 165.955Z"
            fill="#CFB7A1"
          />
          <path
            opacity="0.1"
            d="M112.731 166.596C114.189 167.136 115.792 167.136 117.25 166.596V166.04H112.731V166.596Z"
            fill="#252525"
          />
          <path
            d="M115 166.907C118.578 166.907 121.478 164.007 121.478 160.43C121.478 156.852 118.578 153.952 115 153.952C111.423 153.952 108.523 156.852 108.523 160.43C108.523 164.007 111.423 166.907 115 166.907Z"
            fill="#CFB7A1"
          />
          <path
            d="M108.795 157.974H121.143C121.143 157.974 120.09 152.989 115.42 153.311C110.75 153.634 108.795 157.974 108.795 157.974Z"
            fill="#333333"
          />
          <path
            d="M108.659 161.284C108.992 161.284 109.261 160.778 109.261 160.154C109.261 159.529 108.992 159.023 108.659 159.023C108.327 159.023 108.057 159.529 108.057 160.154C108.057 160.778 108.327 161.284 108.659 161.284Z"
            fill="#CFB7A1"
          />
          <path
            d="M121.307 161.284C121.639 161.284 121.909 160.778 121.909 160.154C121.909 159.529 121.639 159.023 121.307 159.023C120.974 159.023 120.704 159.529 120.704 160.154C120.704 160.778 120.974 161.284 121.307 161.284Z"
            fill="#CFB7A1"
          />
          <path
            opacity="0.1"
            d="M108.795 158.141H121.143C121.143 158.141 120.09 153.156 115.42 153.478C110.75 153.801 108.795 158.141 108.795 158.141Z"
            fill="#252525"
          />
          <path
            d="M123.475 233.618C130.672 233.618 136.507 227.755 136.507 220.523C136.507 213.292 130.672 207.429 123.475 207.429C116.277 207.429 110.443 213.292 110.443 220.523C110.443 227.755 116.277 233.618 123.475 233.618Z"
            fill="#E0E0E0"
          />
          <path
            d="M123.475 283.994C130.672 283.994 136.507 278.131 136.507 270.899C136.507 263.668 130.672 257.805 123.475 257.805C116.277 257.805 110.443 263.668 110.443 270.899C110.443 278.131 116.277 283.994 123.475 283.994Z"
            fill="#E0E0E0"
          />
          <path
            d="M218.208 262.798H149.205V268.3H218.208V262.798Z"
            fill="#E0E0E0"
          />
          <path
            d="M218.208 273.802H149.205V279.304H218.208V273.802Z"
            fill="#EEEEEE"
          />
          <path
            d="M218.208 79.0741H149.205V84.576H218.208V79.0741Z"
            fill="#E0E0E0"
          />
          <path
            d="M218.208 90.0818H149.205V95.5837H218.208V90.0818Z"
            fill="#E0E0E0"
          />
          <path
            d="M181.152 56.3282L149.683 56.8006C149.254 56.807 148.911 57.1601 148.917 57.5893L148.925 58.0982C148.932 58.5274 149.285 58.87 149.714 58.8636L181.183 58.3912C181.612 58.3848 181.955 58.0317 181.948 57.6025L181.941 57.0936C181.934 56.6644 181.581 56.3218 181.152 56.3282Z"
            fill="#DBDBDB"
          />
          <path
            d="M188.979 58.2782C189.664 58.2679 190.21 57.7047 190.2 57.0203C190.19 56.3358 189.626 55.7892 188.942 55.7995C188.258 55.8098 187.711 56.373 187.721 57.0575C187.732 57.7419 188.295 58.2885 188.979 58.2782Z"
            fill="#DBDBDB"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="17.2878"
              y1="291.778"
              x2="135.681"
              y2="10.2994"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#808080" stopOpacity="0.25" />
              <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
              <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="85410.4"
              y1="231365"
              x2="195537"
              y2="84082.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#808080" stopOpacity="0.25" />
              <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
              <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="78239.8"
              y1="28143"
              x2="78239.8"
              y2="18311.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#808080" stopOpacity="0.25" />
              <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
              <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
            </linearGradient>
          </defs>
        </svg>
      </FormContainer>
    )
  }
}

Contact.propTypes = {
  contactHeading: PropTypes.string,
  contactPageHeading: PropTypes.string,
  contactPageSubHeading: PropTypes.string,
}

Contact.defaultProps = {
  contactHeading: ``,
  contactPageHeading: ``,
  contactPageSubHeading: ``,
}
